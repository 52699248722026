import Swiper from 'swiper';
import "polyfill-library-node";
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);

if($('.jsSlider01 .swiper-container').length){
  const swiper = new Swiper('.jsSlider01 .swiper-container', {
    slidesPerView: 'auto',
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: '.jsSlider01 .swiper-button-next',
      prevEl: '.jsSlider01 .swiper-button-prev',
    },
    breakpoints: {
      768: {
        spaceBetween: 28,
      }
    }
  });
}

if($('.sliderStyle03').length){
  var swiper3 = new Swiper('.sliderStyle03 .swiper-container', {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 20,
    initialSlide: 1,
    loop: true,
    navigation: {
      nextEl: '.sliderStyle03 .swiper-button-next',
      prevEl: '.sliderStyle03 .swiper-button-prev',
    },
  });
}


