import $ from 'jquery';
import app from './app';
import './vendors/lightbox';
import 'svgxuse/svgxuse.js';
import './components/swiper.js';
import '../../node_modules/jquery-match-height/jquery.matchHeight.js';
import Shuffle from 'shufflejs';

// ---------------------------------------------------------
// Custom Functions for Front-End
// ---------------------------------------------------------

$(window).on('load resize', function(e) {
  var winH = $(this).innerHeight(),
      headerH = $('.menuBox').innerHeight(),
      navFixH = $('.fixedHeader').innerHeight(),
      rHeaderH = $('.rHeader').innerHeight();

  if ($(this).width() > 992) {
    $('#wrapper').css('padding-top', rHeaderH);
    $('#wrapper').css('padding-bottom', navFixH);
        if(winH <= (navFixH + 859 )) {
          $('.navBox').css('height', winH - headerH - navFixH - 20)
          $('.navBox').css('overflow-y', 'auto')
          $('.navBox').css('overflow-x', 'hidden')
        }
        else {
          $('.navBox').css('height', 'auto')
          $('.navBox').css('overflow-y', 'hidden')
          $('.navBox').css('overflow-x', 'hidden')
        }
  } else {
    $('#wrapper').css('padding-top', '');
    $('#wrapper').css('padding-bottom', '');
    if(winH <= 735) {
      $('.navBox').css('height', winH - headerH)
      $('.navBox').css('overflow-y', 'auto')
      $('.navBox').css('overflow-x', 'hidden')
    }
    else {
      $('.navBox').css('height',  'auto' )
      $('.navBox').css('overflow-y', 'hidden')
      $('.navBox').css('overflow-x', 'hidden')
    }
  }
  $('.navBox .jsSearch').on('click', function() {
    if ($(window).width() > 992) {
          if(winH <= (navFixH + 909 )) {
            $('.navBox').css('height', winH - headerH - navFixH - 20)
          }
          else {
            $('.navBox').css('height', 'auto')
          }
    } else {
      if(winH <= 785) {
        $('.navBox').css('height', winH - headerH)
      }
      else {
        $('.navBox').css('height',  'auto' )
      }
    }
  });
});
$(".jsNavSub li > a").click(function() {
  if ($(window).width() < 993) {
    var elm = $(this);
    if( $(this).next('ul').length > 0) {
      if (elm.next("ul").is(":visible")) {
          elm.parent('li').removeClass("active");
          elm.next("ul").slideUp(200);
      } else {
          elm.next("ul").slideDown(200);
          elm.parent('li').siblings().find('ul').slideUp(200);
          elm.parent('li').siblings().removeClass("active");
          elm.parent('li').addClass("active");
      }
    }
  }
});


$(document).ready(function() {
  // ==============================================================
  // Active Menu
  // ==============================================================
  $('.btnMenu').click(function() {
    $(this).toggleClass('active');
    $(this).parents('.menuBox').next('.navBox').fadeToggle();
  });

  // ==============================================================
  // Accordion
  // ==============================================================
  $(".qaQuest").on('click', function() {
    var elm = $(this);
    if (elm.next(".qaAnswer").is(":visible")) {
        elm.removeClass("active")
            .next(".qaAnswer")
            .slideUp(200);
    } else {
        elm.next(".qaAnswer")
            .slideDown(200)
            .siblings(".qaAnswer")
            .slideUp(200)
            .siblings(".qaQuest")
            .removeClass("active");
        elm.addClass("active");
    }
  });

  $('.jsSearch').on('click', function() {
    $(this).toggleClass('active');
    $(this).next('.searchBox').toggleClass("active");
  });

  $('.selectCustom option').each(function() {
    if ($(this).is(':selected')) {
      $('.resultSelected').text($(this).text());
    }
  });
  $('.selectCustom').on('change', function() {
    $('.resultSelected').text($('.selectCustom option:selected ').text());
  });



  // ==============================================================
  // Style image for IE 11
  // ==============================================================
  var isEgde = /Edge\/\d./i.test(navigator.userAgent);
	var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
	if (isIE11 || isEgde){
		$('.choseList .choseItem .image').each(function () {
			var $container = $(this),
				imgUrl = $container.find('img').prop('src');
			if (imgUrl) {
				$container.css('backgroundImage', 'url(' + imgUrl + ')').addClass('isIE');
			}
		});
	}
  $(window).on('load resize', function(e) {
    $('.jsTabFooter a').on('click', function(e) {
      var elm = $(this);
      if (elm.next(".childNavFooter").is(":visible")) {
          elm.removeClass("active")
              .next(".childNavFooter")
              .slideUp(200);
      } else {
          elm.next(".childNavFooter")
              .slideDown(200)
              .siblings(".childNavFooter")
              .slideUp(200)
              .siblings("a")
              .removeClass("active");
          elm.addClass("active");
      }
      return false;
    });
    if ($(this).width() > 768) {
      $('.jsTabFooter a').unbind('click');
    }
  });

 

  // js load more
function loadMore() {
  if ($('.jsLoadMore > *').length > 1) {
    $('.jsLoadMore > *:gt(0)').hide();
    $('.jsBtnMore').show();
  }

  var winWidth = $(window).width(),
      rHeaderH = $('.rHeader').innerHeight(),
      headerH = $('.headerFixBlock').innerHeight();
        $(window).resize(function() {
            winWidth = $(window).width(),
            rHeaderH = $('.rHeader').innerHeight(),
            headerH = $('.headerFixBlock').innerHeight();
        });
  $('.jsBtnMore a').on('click', function(e) {
    var top = 50,
      el = $(this),
      parentEl = el.parents('.jsBtnMore').prev();
      if(winWidth <= 992) {
        top = 75;
      }
    $('html,body').animate({
      scrollTop: parentEl.offset().top - top
    }, 500);
    el.text() == 'もっと見る' ? el.text('閉じる') : el.text('もっと見る');
    $('.jsLoadMore > *:gt(0)').toggle();
    e.preventDefault();
  });
}
loadMore();


});
$(window).on('load', function(e) {
  var element = document.querySelector('.jsFliter');
  var shuffleInstance = new Shuffle(element, {
    itemSelector: '.jsFliter li'
  });
  function loadFliter($item) {
      var hash = $item.attr('href').replace('#','');
			var text = $item.text();
			shuffleInstance.filter(hash);
  }   
  $('.topicNews a').click(function(e){
      var $temp=$(this);
      $('.topicNews a').removeClass("active");
      $temp.addClass("active");
      loadFliter($temp);
      e.preventDefault();
  });   
  $(".jsTab a").click(function() {
    var elm = $(this);
    $('.topicNews a').removeClass("active");
    if (!elm.parent("li").hasClass("active"))
    {
        elm.parent("li")
            .siblings()
            .removeClass("active");
        elm.parent("li").addClass("active");
        elm.parents(".jsTab")
            .next()
            .children(".tabBox")
            .hide();
        $(this.hash).fadeIn();
        $(this.hash).find('li').eq(0).children('a').addClass('active'); 
        loadFliter($(this.hash).children().children().eq(0).children('a'));
    }
    return false;
  });
  $('.jsGroup .tabBox li').eq(0).children('a').addClass('active'); 
  loadFliter($('.jsGroup .tabBox li').eq(0).children('a'));
});
